<template>
	<div class="order-list">

		<el-card class="filter-container" shadow="never">

			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="100px">
                    <el-row>
                        <el-form-item label="状态：">
                            <el-select v-model="status" value-key="" filterable placeholder="请选择" style="width: 200px">
                                <el-option v-for='item in ["全部","接诊","候诊"]' :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="性别：">
                            <el-select v-model="sex" value-key="" filterable placeholder="请选择" style="width: 200px">
                                <el-option v-for='item in ["男","女"]' :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="挂号科室：">
                            <el-select v-model="departmentId" value-key="" filterable placeholder="请选择" style="width: 200px" @change="getchecktypelist(departmentId)">
                                <el-option v-for='item in registerDeptlist' :key="item.deptNo" :label="item.deptName" :value="item.deptNo">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="检查类型：">
                            <el-select v-model="departmentKId" value-key="" filterable placeholder="请选择" style="width: 220px">
                                <el-option v-for='item in checktypelist' :key="item.deptNo" :label="item.deptName" :value="item.deptNo">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>

                    <el-row>
                        <el-form-item label="挂号日期：">
                            <el-date-picker v-model="registDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 510px">
                            </el-date-picker>
                        </el-form-item>


                        <el-form-item label="搜索姓名：">
                            <el-input style="width: 200px" v-model="name" placeholder="姓名"></el-input>
                        </el-form-item>

                        <el-form-item label="电话号：">
                            <el-input style="width: 220px" v-model="tel" placeholder="电话号"></el-input>
                        </el-form-item>

                        <el-form-item style="float: right;">
                            <el-button type="primary" size="small" @click="getonelist()">
                                查询
                            </el-button>
                            <el-button type="info"   size="small" @click="clearlist">
                                重置
                            </el-button>
                            <el-button  type="warning" plain size="small" @click="alldowl()">
                                导出全部
                            </el-button>
                            <el-button  type="danger" plain size="small" @click="dowl()">
                                导出选中
                            </el-button>
                        </el-form-item>
                    </el-row>

				</el-form>
			</div>
		</el-card>


		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>预约记录列表</span></el-col>
				<el-col style="display: flex;justify-content: flex-end;">

<!--					<el-button class="btn-add" size="mini" @click="getlist()">
						刷新
					</el-button>-->
					<!-- <el-button class="btn-add" size="mini">
						添加
					</el-button> -->
				</el-col>
			</el-row>
		</el-card>
		<el-card class="filter-container" shadow="never" style="margin: 20px 0;">
			<el-table :data="list" style="width: 100%;" height="750px" @selection-change="checkAll">
				<el-table-column type="selection" width="55" align="center">
				</el-table-column>
        <el-table-column label="条码号" fixed>
          <template slot-scope="scope">
            <span>{{ scope.row.orderSn }}</span>
          </template>
        </el-table-column>

				<el-table-column label="姓名" fixed>
					<template slot-scope="scope">
						<span v-if="scope.row.departmentId != '300001'">{{ scope.row.patientName }}</span>
            <span v-else>{{ scope.row.patientName }},{{ scope.row.subjoinPatientName }}</span>
					</template>
				</el-table-column>

				<el-table-column label="性别">
					<template slot-scope="scope">
						<span  v-if="scope.row.departmentId != '300001'"> {{ scope.row.patientSex }}</span>
            <span  v-else> {{ scope.row.patientSex }},女</span>
					</template>
				</el-table-column>

				<el-table-column label="年龄">
					<template slot-scope="scope">
						<span>{{ scope.row.patientAge }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="联系方式">
					<template slot-scope="scope">
						<span>{{ scope.row.patientMobile }}</span>
					</template>
				</el-table-column>

				<el-table-column label="挂号科室">
					<template slot-scope="scope">
						<span>{{ scope.row.departmentName }}</span>
					</template>
				</el-table-column>

        <el-table-column label="检查类型">
          <template slot-scope="scope">
            <span>{{ scope.row.departmentKName }}</span>
          </template>
        </el-table-column>

				<el-table-column label="接诊医师">
					<template slot-scope="scope">
						<span>{{ scope.row.doctorName }}</span>
					</template>
				</el-table-column>

				<el-table-column label="接诊时间">
					<template slot-scope="scope">
						<span>早8:30-11:30</span>
					</template>
				</el-table-column>
				
				<el-table-column label="预约类型">
					<template slot-scope="scope">
						<span>{{ scope.row.registrationType }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="挂号日期">
					<template slot-scope="scope">
						<span>{{ scope.row.clinicDate }}</span>
					</template>
				</el-table-column>
        <el-table-column label="挂号时间段">
          <template slot-scope="scope">
            <span>{{ scope.row.clinicPeriod }}</span>
          </template>
        </el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span>{{ scope.row.orderState }}</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" v-if="false">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click="sendOrder(scope.row)" >发送病例</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<el-pagination :hide-on-single-page="hideonsinglepage" background :current-page="pageNo" @current-change="pagebtn"
		 :total="box.totalCount" :page-size="box.pageSize" layout="prev, pager, next">
		</el-pagination>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '', //搜索
				registDate: '', //时间
				sex: '', //性别
				status: "", //状态
        departmentId: '', //挂号科室 父科室ID
        departmentKId: '', //检查类型 子科室ID
				checktypelist:[],//检测类型列表
				name: '', //姓名
				tel: '', //电话号

				list: [], //循环列表 
				registerDeptlist: [], //挂号科室列表
				row: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true,
				tableData: [], //列表循环数据
				ids: ''

			}
		},
		methods: {
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
			
			onSubmit() {
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			// 点击获取选中的
			checkAll(e) {
				const that = this;
				that.ids = e;
			},
			
			// 获取检测类型
			getchecktypelist(departmentId){
				const that=this;
				that.api('api/department/get_check_items',{departmentId: departmentId}).then(res=>{
					that.checktypelist=res.checkItems;
          console.log(1111111111111111)
          console.log(res.checkItems)
				})
			},
			
			// 获取列表
			getlist() {
				const that = this;
        let sexIndex = '';
        if(that.sex == '男'){
          that.sexIndex = '1'
        } else if(that.sex == '女'){
          that.sexIndex = '2'
        }
				that.api('admin/registration/list', {
					pageSize: 10,
					pageNum: that.pageNo,
					status: that.status=='全部' ? '' : that.status,
					sex: that.sexIndex,  // 1男2女3保密
          departmentId: that.departmentId,
					name: that.name,
					tel: that.tel,
          clinicDateBegin:that.registDate[0],
          clinicDateDateEnd:that.registDate[1],
          departmentKId:that.departmentKId
				}).then(res => {
          console.log(res);
					that.box = res.data;
					that.list = res.data.pageList;
					that.pageNo = res.data.pageNm;
					that.pageshow = false; //让分页隐藏
					that.$nextTick(() => { //重新渲染分页
						that.pageshow = true;
					});
				})
			},
			//发送病例
			sendOrder(e) {
				const that = this;
				console.log(e)
				that.api('/admin/member/sendMedicalRecord', {
					dataInfoId: e.dataInfoDetailId,
					name:e.name,
					tel:e.tel
				}).then(res => {
					if (res.code == 200) {
						that.$message({
							showClose: true,
							type: "success",
							message: "发送成功"
						})
					}
				})
			},

			// 获取挂号科室列表
			getregisterDeptlist() {
				const that = this;
				that.api('/api/department/list', {
					pageSize: 100,
					pageNo: 1,
          parentNo: '0'
				}).then(res => {
					that.registerDeptlist = res.data;
				})
			},


			// 导出
			dowl() {
				const that = this;
				var ids = [];

				for (var i = 0; i < that.ids.length; i++) {
					ids.push(that.ids[i].id);
				}
				if(ids.length!=0){
					that.api('/admin/registration/export', {
						ids: ids,
						
					}).then(res => {
						if (res.code == 200) {
							window.location.href = that.$utils.apiurl + res.data;
						}
					})
				}else{
					
				}	
				
			},
			//全部导出
			alldowl(){
				const that=this;
        let sexIndex = '';
        if(that.sex == '男'){
          that.sexIndex = '1'
        } else if(that.sex == '女'){
          that.sexIndex = '2'
        }
				that.api('/admin/registration/export', {
					status: that.status=='全部' ? '' : that.status,
					sex: that.sexIndex,
          departmentId: that.departmentId,
					name: that.name,
					tel: that.tel,
          clinicDateBegin:that.registDate[0],
          clinicDateDateEnd:that.registDate[1],
          departmentKId:that.departmentKId
				}).then(res => {
					if (res.code == 200) {
						window.location.href = that.$utils.apiurl + res.data;
					}
				})
			},
			

			// 重置搜索
			clearlist() {
				const that = this;
				that.status = '';
				that.sex = '';
        that.sexIndex = '';
				that.departmentId = '';
				that.name = '';
				that.tel = '';
				that.registDate = '';
				that.departmentKId='';
				that.pageNo=1;
				that.getlist();
			},

		},
		created() {
			const that = this;
			that.getlist();
			that.getregisterDeptlist();
		}
	}
</script>

<style lang="less" scoped>

</style>
